<template>
  <a-modal
    title="编辑"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 违规信息 -->
            <a-form-item label="违规信息" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-textarea
                v-decorator="['illegalMessage', { rules: [{ required: true, message: '请输入！' }] }]"
                placeholder="请输入该商家违规信息"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { 
  storeIllegalMessageEdit,
  storeIllegalMessageDetail,
} from '@/api/modular/mallLiving/storeIllegalMessage'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:'',  //商家违规信息id
      storeId:''  //商家id
    }
  },

  methods: {
    // 初始化方法
    edit(record) {
      this.visible = true
      this.confirmLoading = true
      this.id = record.id
      this.storeId = record.storeId
      //调取详情接口，回显数据
      setTimeout(()=>{
        storeIllegalMessageDetail({id: this.id}).then((res)=>{
          if(res.success){
              var details = res.data
              setTimeout(()=>{
                this.form.setFieldsValue({
                  illegalMessage: details.illegalMessage
                })
                this.confirmLoading = false
              }, 200)
          }
        })
      }, 200)
    },
    //提交操作
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
            storeIllegalMessageEdit({id: this.id, storeId:this.storeId, ...values}).then((res) => {
              if(res.success){
                this.$message.success("编辑成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    }
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>