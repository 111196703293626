//商家违规信息接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-03-8
 */

//商家违规信息 分页查询
export function storeIllegalMessagePageList(parameter){
    return axios({
        url: '/storeIllegalMessage/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//商家违规信息 详情
export function storeIllegalMessageDetail(parameter){
    return axios({
        url: '/storeIllegalMessage/detail',
        method: 'post',
        params: parameter 
    })
}
//商家违规信息 添加
export function storeIllegalMessageAdd(parameter){
    return axios({
        url: '/storeIllegalMessage/add',
        method: 'post',
        data: parameter 
    })
}
//商家违规信息 编辑
export function storeIllegalMessageEdit(parameter){
    return axios({
        url: '/storeIllegalMessage/edit',
        method: 'post',
        data: parameter 
    })
}
//商家违规信息 修改状态
export function storeIllegalMessageDelete(parameter){
    return axios({
        url: '/storeIllegalMessage/delete',
        method: 'post',
        data: parameter 
    })
}
